<template>
  <UiPopup
    :model-value="modelValue"
    title="Lead is duplicated"
    description="Lead is currently in close stage. Would you like to create a duplicate of this lead or reopen it?"
    primary-button-text="Create duplicate"
    secondary-button-text="Reopen"
    size="extraSmall"
    :loading="loading"
    @update:model-value="emits('update:modelValue', false)"
    @cancel="navigateTo(`/leads/${props.duplicateId}`)"
    @confirm="confirm"
  />
</template>
<script setup lang="ts">
import type { Lead } from '@/types'
import { useUiStore } from '~/store/ui'

const emits = defineEmits(['update:modelValue', 'created'])

type Props = {
  modelValue: boolean
  lead: Lead
  duplicateId: string
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const loading = ref(false)

const uiStore = useUiStore()
const confirm = async () => {
  const payload = {
    ...props.lead,
    skip_duplicate_validation: true,
  }

  loading.value = true

  const { data, error } = await useCreateLead(payload)

  if (data.value?.data) {
    emits('created', data.value?.data)
  }

  if (error.value) {
    uiStore.showSnackBanner('', 'error')
  }

  loading.value = false
}
</script>
